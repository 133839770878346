<template>
  <section>
    <base-card>
      <v-card-text
          class="text-xs-center text-lg-justify text-lg-h6 text-sm-subtitle-1 font-weight-light mx-auto mb-5"
      >
        {{$t('youth.text') }}
      </v-card-text>
      <v-card-title
          id="mission"
          primary-title
          class="display-2 justify-center font-weight-bold grey--text text--darken-3 mb-3"
      >
        {{ $t('youth.mission') }}
      </v-card-title>
      <v-row dense>
        <member-card
            v-for="(aspect, i) in youthAspects"
            :key="`e-${i}`"
            :img="aspect.acf.youth_aspects_img"
        >
          <template v-slot:expandContent>
            <v-row
                justify="center"
                align-content="space-between"
                class="d-flex transition-fast-in-fast-out black darken-2 v-card--reveal white--text pa-0 ma-0"
                style="height: 100%;"
            >
              <v-col
                  cols="12"
                  align-self="center"
              >
                  <span
                      :class="$vuetify.breakpoint.mdAndUp ? 'display-3' : 'headline'"
                  >
                    {{ aspect.acf.youth_aspects_title[currentLang] }}
                  </span>
                <v-divider
                    dark
                />
              </v-col>
              <v-col>
                  <span class="text-lg-h6 text-sm-subtitle-1 font-weight-light mb-5">
            {{ aspect.acf.youth_aspects_text[currentLang] }}
          </span>
              </v-col>
            </v-row>
          </template>
        </member-card>
      </v-row>
      <v-card-text
          id="youth"
          class="text-xs-center text-lg-justify text-lg-h6 text-sm-subtitle-1 font-weight-light mx-auto mb-5"
          v-html="youth.document_content[currentLang]"
      >
      </v-card-text>
      <v-card>
        <v-card-title>
          {{$t('youth.download') }}
        </v-card-title>
        <v-card-text>
          <v-icon
              class="mr-3"
          >
            mdi-file-pdf
          </v-icon>
          <a :href="youth.document_document" target="_blank">{{ youth.document_name[currentLang] }}.pdf</a>
        </v-card-text>
      </v-card>
    </base-card>
  </section>
</template>

<script>
import i18n from '@//plugins/i18n'
import MemberCard from '@/components/core/MemberCard'

export default {
  components: {MemberCard},
  props: {
    youth: {
      type: Object,
      default: () => ({})
    },
    youthAspects: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    currentLang() {
      return i18n.locale
    }
  }
}
</script>
