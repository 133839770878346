<template>
  <v-col
      cols="12"
      sm="4"
  >
    <v-sheet
        class="mb-5"
    >
      <v-container>
        <v-hover v-slot="{ hover }">
          <kinesis-container :event="$vuetify.breakpoint.mdAndUp ? 'move' : 'scroll'">
            <kinesis-element  :strength="0.5" type="scale">
              <v-img
                  class="rounded-lg"
                  :src="img"
                  aspect-ratio="0.5625"
                  :max-height="$vuetify.breakpoint.mdAndUp ? 800 : 300"
              >
                <v-expand-transition>
                  <slot name="expandContent" v-if="$vuetify.breakpoint.mdAndUp ? hover : true"></slot>
                </v-expand-transition>
              </v-img>
            </kinesis-element>
          </kinesis-container>
        </v-hover>
      </v-container>
    </v-sheet>
  </v-col>
</template>

<script>
import{KinesisElement, KinesisContainer} from 'vue-kinesis'

export default {
  name: "MemberCard",
  components:{
    KinesisContainer,
    KinesisElement
  },
  props:{
    img: {
      type: String,
      default: () => ('')
    }
  }
}
</script>

<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: .7;
  position: absolute;
  width: 100%;
}
</style>
